import {
  NEW_CONTRACT_ID,
  RATE_MULTIPLIER,
  SPACE_UNIT_MULTIPLIER,
} from 'shared/constants/contract.constant'
import { SpaceUnit } from 'shared/enum/space-unit.enum'
import { TransportSubmode } from 'shared/enum/transport-submode.enum'
import { IAddress } from 'shared/interface/address.interface'
import { IBreakpoint } from 'shared/interface/breakpoint.interface'
import { IConfiguredSurcharge } from 'shared/interface/configured-surcharge.interface'
import { IContact } from 'shared/interface/contact.interface'
import { IContractLane } from 'shared/interface/contract-lane.interface'
import { IContract } from 'shared/interface/contract.interface'

import { Geolocation } from 'proto/common/geolocation_pb'
import { Owner } from 'proto/common/owner_pb'
import {
  Breakpoint,
  ConfiguredSurcharge,
  Contract,
  Lane,
  RoadTypes,
} from 'proto/contract/v1/contract_pb'
import { Address } from 'proto/crm/v1/address_pb'
import { Contact } from 'proto/crm/v1/contact_pb'

import { createDateFromString, fromMoment, timestampToMoment } from 'helpers/timestamp'

import { ILaneType } from 'shared/interface/contract-lane-type.interface'
import { TRoadTypeSpaceUnit } from 'shared/types/space-unit.type'

import { fromSurchargeProto, toSurchargeProto } from './surcharge.mapper'

const fromContractProto = (proto: Contract): IContract => {
  const contract: IContract = {
    contractId: proto.getContractId(),
    contractRef: proto.getContractRef(),
    status: proto.getStatus(),
    organizationId: proto.getOrganizationId(),
    branchId: proto.getBranchId(),
    createdBy: proto.getCreatedBy(),
    benefitCalculation: proto.getBenefitCalculation(),
    currency: proto.getCurrency(),
    name: proto.getName(),
    salesRepresentative: proto.getSalesRepresentative(),
    transportMode: proto.getTransportMode(),
    lanes: fromLaneProto(proto.getLanesList()),
    updatedBy: proto.getUpdatedBy(),
    createdAt: timestampToMoment(proto.getCreatedAt()).format(),
    updatedAt: timestampToMoment(proto.getUpdatedAt()).format(),
    validFrom: timestampToMoment(proto.getValidFrom()).format(),
    validTo: timestampToMoment(proto.getValidTo()).format(),
    // internalNote: proto.getInternalNote() || '', fix this when backend supports it.
  }
  return contract
}

const fromLaneProto = (protoLane: Lane[]): IContractLane[] => {
  const contractLanes: IContractLane[] = protoLane.map((lane) => {
    return {
      laneId: lane.getLaneId(),
      minPrice: lane.getMinPrice(),
      maxPrice: lane.getMaxPrice(),
      maxChargeableWeight: lane.getMaxChargeableWeight(),
      laneType: fromLaneTypeProto(lane),
      laneReceiver: fromAddressProto(lane.getLaneReceiver()!),
      laneSender: fromAddressProto(lane.getLaneSender()!),
      breakpoints: fromBreakpointProto(lane.getBreakpointsList()),
      transportSubmode: lane.getRoadType()?.getTransportSubmode() || TransportSubmode.UNSPECIFIED,
      spaceUnit: (lane.getRoadType()?.getSpaceUnit() as SpaceUnit) || SpaceUnit.OTHER,
      // unitConversions: fromUnitConversionProto(lane.getUnitConversion()!),
      calculationType: lane.getCalculationType(),
      configuredSurcharges: fromConfiguredSurchargeProto(lane.getConfiguredSurchargesList()) ?? [],
    }
  })
  return contractLanes
}

const fromConfiguredSurchargeProto = (proto: ConfiguredSurcharge[]): IConfiguredSurcharge[] => {
  return proto.map((ConfiguredSurcharge) => {
    return {
      surchargeId: ConfiguredSurcharge.getSurcharge(),
      configuredSurchargeId: ConfiguredSurcharge.getConfiguredSurchargeId(),
      isIncluded: ConfiguredSurcharge.getIsIncluded(),
      isRequired: ConfiguredSurcharge.getIsRequired(),
      surcharge: ConfiguredSurcharge.getSurcharge()
        ? fromSurchargeProto(ConfiguredSurcharge.getSurcharge()!)
        : undefined,
    }
  })
}

const fromLaneTypeProto = (proto: Lane): ILaneType => {
  const laneType: ILaneType = {
    transportSubmode: TransportSubmode.UNSPECIFIED,
    spaceUnit: SpaceUnit.OTHER,
  }
  if (proto.hasRoadType()) {
    const roadType = proto.getRoadType()
    if (!roadType) {
      return laneType
    }
    laneType.transportSubmode = roadType.getTransportSubmode()
    laneType.spaceUnit = roadType.getSpaceUnit() as TRoadTypeSpaceUnit
  }
  return laneType
}

const fromBreakpointProto = (proto: Breakpoint[]): IBreakpoint[] => {
  return proto.map((Breakpoint) => {
    return {
      breakpointId: Breakpoint.getBreakpointId(),
      spaceUnitQuantity: Breakpoint.getSpaceUnitQuantity() / SPACE_UNIT_MULTIPLIER,
      rate: Breakpoint.getRate() / RATE_MULTIPLIER,
    }
  })
}

const fromAddressProto = (proto: Address): IAddress => {
  return {
    address1: proto.getAddress1(),
    address2: proto.getAddress2(),
    address3: proto.getAddress3(),
    addressId: proto.getAddressId(),
    countryId: proto.getCountryId(),
    county: proto.getCounty(),
    internalInstruction: proto.getInternalInstruction(),
    externalInstruction: proto.getExternalInstruction(),
    name: proto.getName(),
    postalcode: proto.getPostalcode(),
    timezone: proto.getTimezone(),
    usage: proto.getUsageList(),
    contact: fromContactProto(proto.getContact()),
    geolocation: {
      lat: proto.getGeolocation()?.getLat() || 0,
      lng: proto.getGeolocation()?.getLng() || 0,
    },
    owner: proto.getOwner(),
    city: proto.getCity(),
  }
}

const fromContactProto = (proto?: Contact): IContact => {
  return {
    contactId: proto?.getContactId() || 0,
    email: proto?.getEmail() || '',
    name: proto?.getName() || '',
    phone: proto?.getPhone() || '',
    surname: proto?.getSurname() || '',
    owner: proto?.getOwner(),
  }
}

const toContractProto = (contract: IContract): Contract => {
  const proto = new Contract()
  proto.setBenefitCalculation(contract.benefitCalculation)
  proto.setBranchId(contract.branchId)
  if (contract.contractId !== NEW_CONTRACT_ID) {
    proto.setContractId(contract.contractId)
  }
  proto.setContractRef(contract.contractRef)
  proto.setCreatedAt(fromMoment(createDateFromString(contract.createdAt)))
  proto.setCreatedBy(contract.createdBy)
  proto.setCurrency(contract.currency)
  proto.setLanesList(toLaneProto(contract.lanes))
  proto.setName(contract.name)
  proto.setOrganizationId(contract.organizationId)
  proto.setSalesRepresentative(contract.salesRepresentative)
  proto.setStatus(contract.status)
  proto.setTransportMode(contract.transportMode)
  proto.setUpdatedAt(fromMoment(createDateFromString(contract.updatedAt)))
  proto.setUpdatedBy(contract.updatedBy)
  proto.setValidFrom(fromMoment(createDateFromString(contract.validFrom)))
  proto.setValidTo(fromMoment(createDateFromString(contract.validTo)))
  return proto
}

const toLaneProto = (contractLanes: IContractLane[]): Lane[] => {
  return contractLanes.map((contractLane) => {
    const lane = new Lane()
    const roadType = new RoadTypes()
    roadType.setSpaceUnit(contractLane.laneType?.spaceUnit as RoadTypes.SpaceUnit)
    roadType.setTransportSubmode(
      contractLane.laneType?.transportSubmode as RoadTypes.TransportSubmode,
    )

    lane.setMinPrice(contractLane.minPrice ?? 0)
    lane.setMaxPrice(contractLane.maxPrice ?? 0)
    lane.setMaxChargeableWeight(contractLane.maxChargeableWeight ?? 0)
    if (contractLane.laneId !== NEW_CONTRACT_ID) {
      lane.setLaneId(contractLane.laneId)
    }
    lane.setRoadType(roadType)
    lane.setLaneReceiver(toAddressProto(contractLane.laneReceiver!))
    lane.setLaneSender(toAddressProto(contractLane.laneSender!))
    lane.setBreakpointsList(toBreakpointProto(contractLane.breakpoints))
    lane.setCalculationType(contractLane.calculationType)
    lane.setConfiguredSurchargesList(
      toConfiguredSurchargeProto(contractLane.configuredSurcharges || []),
    )
    return lane
  })
}

const toConfiguredSurchargeProto = (
  configuredSurcharges: IConfiguredSurcharge[],
): ConfiguredSurcharge[] => {
  return configuredSurcharges.map((configuredSurcharge) => {
    const proto = new ConfiguredSurcharge()
    proto.setConfiguredSurchargeId(configuredSurcharge.configuredSurchargeId)
    proto.setSurcharge(toSurchargeProto(configuredSurcharge.surcharge!))
    proto.setIsRequired(configuredSurcharge.isRequired)
    proto.setIsIncluded(configuredSurcharge.isIncluded)
    proto.setSurcharge(
      configuredSurcharge.surcharge ? toSurchargeProto(configuredSurcharge.surcharge) : undefined,
    )
    return proto
  })
}

const toBreakpointProto = (Breakpoints: IBreakpoint[]): Breakpoint[] => {
  return Breakpoints.map((bp) => {
    const proto = new Breakpoint()
    proto.setBreakpointId(bp.breakpointId || 0)
    proto.setSpaceUnitQuantity(bp.spaceUnitQuantity * SPACE_UNIT_MULTIPLIER)
    proto.setRate(bp.rate * RATE_MULTIPLIER)
    return proto
  })
}

const toAddressProto = (address: IAddress): Address => {
  const proto = new Address()
  const geolocation = new Geolocation()
  geolocation.setLat(address.geolocation?.lat || 0)
  geolocation.setLng(address.geolocation?.lng || 0)

  proto.setAddress1(address.address1)
  proto.setAddress2(address.address2)
  proto.setAddress3(address.address3)
  proto.setAddressId(address.addressId)
  proto.setCity(address.city)
  proto.setContact(toContactProto(address.contact))
  proto.setCountryId(address.countryId)
  proto.setCounty(address.county)
  proto.setExternalInstruction(address.externalInstruction)
  proto.setGeolocation(geolocation)
  proto.setInternalInstruction(address.internalInstruction)
  proto.setName(address.name)
  proto.setOwner(address.owner)
  proto.setPostalcode(address.postalcode)
  proto.setTimezone(address.timezone)
  proto.setUsageList(address.usage || [])

  return proto
}

const toContactProto = (contact?: IContact): Contact => {
  const proto = new Contact()
  proto.setContactId(contact?.contactId || 0)
  proto.setEmail(contact?.email || '')
  proto.setName(contact?.name || '')
  proto.setPhone(contact?.phone || '')
  proto.setSurname(contact?.surname || '')
  proto.setOwner(contact?.owner || new Owner())
  return proto
}

export { fromContractProto, toContractProto }
